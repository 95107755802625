import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import routes from './router/index.js'
import {
	_http
} from './utils/http';
import _comm from './utils/common';
import storage from '@/utils/storage';
import RouterTab from 'vue-router-tab'
import 'vue-router-tab/dist/lib/vue-router-tab.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/base.scss';
import customPagination from './components/customPagination';
import customHomePagination from './components/customHomePagination';

// 高德地图
import VueAMap from 'vue-amap';
/*********************高德地图初始化*********************/
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
	// 高德的key:2faaca36dc73b043479bb59b21e697fc
	key: '2faaca36dc73b043479bb59b21e697fc',
	plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar',
		'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation', 'MarkerClusterer',
		'AMap.Heatmap', 'AMap.DistrictLayer', 'AMap.Geocoder'
	],
	// 默认高德 sdk 版本为 1.4.4
	v: '1.4.15'
});

import JsonExcel from 'vue-json-excel';
Vue.component('downloadExcel', JsonExcel);

const router = new VueRouter({
	mode: 'hash',
	routes: routes
})
Vue.use(VueRouter);
Vue.use(ElementUI);
Vue.use(RouterTab);
//重写message只显示最新一个
import resetMessage from './utils/message';
Vue.prototype.$message = resetMessage;
Vue.use(customPagination);
Vue.use(customHomePagination);
Vue.prototype._http = _http; //api调用方式
Vue.prototype._comm = _comm; //公共方法使用
Vue.prototype._storage = storage;
Vue.config.productionTip = false
new Vue({
	router,
	render: h => h(App),
}).$mount('#app')