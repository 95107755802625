import Router from 'vue-router';

let Home = () => import("../views/Home.vue");

const basicRoutes = [{
		path: "/",
		name: "login",
		title: "一杆一码管理平台",
		redirect: "/login",
	},
	{
		path: "/index",
		name: "index",
		title: "赋码管理",
		meta: {
			title: "赋码管理",
		},
		redirect: "/index",
		component: Home,
		children: [{
			path: "/index",
			name: "index",
			title: "赋码管理",
			meta: {
				title: "赋码管理",
			},
			component: () => import("../views/index/Index.vue"),
		}],
	},
	{
		path: "/login",
		name: "login",
		title: "登录",
		component: () => import("../views/login/Login.vue"),
	},
	/* {
		path: "/manageDetail",
		name: "manageDetail",
		title: "详情",
		meta: {
			title: "详情",
		},
		component: Home,
		children: [{
			path: "/manageDetail",
			name: "manageDetail",
			title: "详情",
			meta: {
				title: "详情",
			},
			component: () => import("../views/manageDetail/manageDetail.vue"),
		}, ],
	}, */
	{
		path: "/problemManage",
		name: "problemManage",
		title: "问题管理",
		meta: {
			title: "问题管理",
		},
		component: Home,
		children: [{
			path: "/problemManage",
			name: "problemManage",
			title: "问题管理",
			meta: {
				title: "问题管理",
			},
			component: () => import("../views/problemManage/problemManage.vue"),
		}],
	},
	{
		path: "/maintainManage",
		name: "maintainManage",
		title: "维护管理",
		meta: {
			title: "维护管理",
		},
		component: Home,
		children: [{
			path: "/maintainManage",
			name: "maintainManage",
			title: "维护管理",
			meta: {
				title: "维护管理",
			},
			component: () => import("../views/maintainManage/maintainManage.vue"),
		}],
	},
	{
		path: "/userManage",
		name: "userManage",
		title: "用户管理",
		meta: {
			title: "用户管理",
		},
		component: Home,
		children: [{
			path: "/userManage",
			name: "userManage",
			title: "用户管理",
			meta: {
				title: "用户管理",
			},
			component: () => import("../views/userManage/userManage.vue"),
		}, ],
	},
	{
		path: "/rolesManage",
		name: "rolesManage",
		title: "角色管理",
		meta: {
			title: "角色管理",
		},
		component: Home,
		children: [{
			path: "/rolesManage",
			name: "rolesManage",
			title: "角色管理",
			meta: {
				title: "角色管理",
			},
			component: () => import("../views/rolesManage/rolesManage.vue"),
		}, ],
	},
	{
		path: "/citizenManage",
		name: "citizenManage",
		title: "市民管理",
		meta: {
			title: "市民管理",
		},
		component: Home,
		children: [{
			path: "/citizenManage",
			name: "citizenManage",
			title: "市民管理",
			meta: {
				title: "市民管理",
			},
			component: () => import("../views/citizenManage/citizenManage.vue"),
		}, ],
	},
	{
		path: "/rootManage",
		name: "rootManage",
		title: "权限管理",
		meta: {
			title: "权限管理",
		},
		component: Home,
		children: [{
			path: "/rootManage",
			name: "rootManage",
			title: "权限管理",
			meta: {
				title: "权限管理",
			},
			component: () => import("../views/rootManage/rootManage.vue"),
		}, ],
	},
];

// 获取原型对象push函数
const originalPush = Router.prototype.push

// 获取原型对象replace函数
const originalReplace = Router.prototype.replace

// 修改原型对象中的push函数
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
Router.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch(err => err)
}


export default basicRoutes;