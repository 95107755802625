<template>
	<div class="pagination-container" v-if="paginationData.total">
		<el-pagination background @current-change="handleCurrentChange" :current-page="paginationData.page"
			:page-size="paginationData.pageSize" @size-change="handleSizeChange"
			:page-sizes="paginationData.pageSizes ? paginationData.pageSizes : pageSizes"
			:layout="paginationData.layOuts ? paginationData.layOuts : layOuts"
			:total="paginationData.total"></el-pagination>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				pageSizes: [20, 50, 200],
				layOuts: 'total,sizes,prev,pager,next,jumper'
			};
		},
		// 外部参数传递model
		// paginationData: {
		//   pageSize: 15,
		//   pageIndex: 1,
		//   total: null
		// }
		props: ['paginationData'],
		created() {
			if (this.pageSizes.indexOf(this.paginationData.pageSize) == -1) {
				this.pageSizes.push(this.paginationData.pageSize);
				this.pageSizes.sort((a, b) => {
					return a - b;
				});
			}
		},
		methods: {
			// 分页
			handleSizeChange(val) {
				this.paginationData.pageSize = val;
				this.paginationData.page = 1;
				this.$emit('getList');
			},
			handleCurrentChange(val) {
				this.paginationData.page = val;
				this.$emit('getList');
				// console.log('22222222222222222');
				// console.log('val', val);
			}
		}
	};
</script>

<style lang="scss">
	.pagination-container {
		.el-pagination {
			font-size: 14px;
			font-family: 'PingFang SC-Regular', 'PingFang SC';
			font-weight: 400;
			color: #31373d;
			line-height: 14px;

			.el-pagination__sizes .el-input .el-input__inner:hover {
				border-color: #37b8a2;
			}

			.el-select .el-input.is-focus .el-input__inner {
				border-color: #37b8a2;
			}

			&.is-background .btn-prev,
			&.is-background .btn-next,
			&.is-background .el-pager li {
				border-radius: 4px 4px 4px 4px;
				border: 1px solid #d4d6d9 !important;
				color: #bbbdbf;
				background-color: #fff;
			}

			&.is-background .btn-prev:hover,
			&.is-background .btn-next:hover {
				border: 1px solid #37b8a2 !important;
				color: #37b8a2;
			}

			&.is-background .el-pager li:not(.disabled):hover {
				border: 1px solid #37b8a2 !important;
				color: #37b8a2;
			}

			&.is-background .el-pager li:not(.disabled).active {
				border: 1px solid #37b8a2 !important;
				color: #37b8a2;
				background-color: #fff;
			}
		}
	}
</style>